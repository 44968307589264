@import './fontsLocal';

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Italian Plate No2 Expanded';

    overflow-x: hidden;
}

h1, h2, h3,  h4, h5 {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
}

* {
    box-sizing: border-box;
}
a {
    text-decoration: unset;
}
body {
    transition: background 0.8s ease;
}
body.lock-scroll {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
}
body.dark {
    background-color: #000000;
}
