@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Black.tff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Black-Italic.tff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Bold.ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Bold-Italic.ttf');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Demibold.ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Demibold-Italic.ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Extrabold.ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Extrabold-Italic.ttf');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Extralight-Italic.ttf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Extralight.ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Italic.ttf');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Light-Italic.ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Light.ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Medium-Italic.ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Thin-Italic.ttf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Thin.ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Ultralight-Italic.ttf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded-Ultralight.ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('/fonts/Italian-Plate-No2-Expanded.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('/fonts/RobotoMono-Regular.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
